import React from "react";
import {
  Grid,
  Button,
  Stack,
  Avatar,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import TranslateIcon from "@mui/icons-material/Translate";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

// Import the language
import { supportedLanguages } from "../data/data";

export const FirstLoad = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation(); // Initialize the useTranslation hook

  // Check if a language is stored in local storage and set it as the initial language
  React.useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const renderLanguageButton = (
    language: string,
    name: string,
    flagUrl: string,
    route: string
  ) => {
    const handleLanguageChange = () => {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language); // Store the selected language in local storage
      navigate(`/${language}${route}`); // Include the language prefix in the route
    };

    return (
      <Grid item key={language}>
        <Paper>
          <Button onClick={handleLanguageChange}>
            <Stack direction='row' alignItems='center' padding='10px'>
              <Avatar alt='Logo' src={flagUrl} />
              <Typography paddingLeft='10px'>{name}</Typography>
            </Stack>
          </Button>
        </Paper>
      </Grid>
    );
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100vh'>
      <Grid container spacing={2} justifyContent='space-evenly'>
        {supportedLanguages.map((lang) =>
          renderLanguageButton(lang.code, lang.name, lang.flag, lang.route)
        )}

        <Grid item key={"crowdin"}>
          <Paper>
            <Link
              to='https://crowdin.com/project/el-jardi-dels-ocells-simp'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: "none", color: "inherit" }}>
              <Button>
                <Stack direction='row' alignItems='center' padding='10px'>
                  <TranslateIcon />
                  <Typography paddingLeft='10px'>Help us add more</Typography>
                </Stack>
              </Button>
            </Link>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstLoad;
