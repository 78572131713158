import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "DM-Mono, sans-serif",
    fontSize: 18,

    h4: {
      fontFamily: "Lucida-Calligraphy, sans-serif",
      fontWeight: 800,
      "@media (max-width:600px)": {fontSize: 23,},
    },
    h5: {
      fontFamily: "Lucida-Calligraphy, sans-serif",
      fontWeight: 800,
      "@media (max-width:600px)": {fontSize: 21.5,},
    },
    h6: {
      fontFamily: "Lucida-Calligraphy, sans-serif",
      fontWeight: 800,
      "@media (max-width:600px)": {fontSize: 20,},
    },
    caption:{fontSize:14}
  },
  palette: {
    background: {
      default: "#8bc34a",
    },
    primary: {
      main: "#4caf50", // Change the primary color
    },
    secondary: {
      main: "#ffea00", // Change the secondary color
    },
  },
});

export default theme;
