import React from "react";
import {
  Grid,
  List,
  ListItem,
  Avatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ForestIcon from "@mui/icons-material/Forest";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";

export const Information = () => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Grid padding={2}>
        <h2>{t("information.title")}</h2>
        <p>{t("information.text")}</p>
        <p>{t("information.text2")}</p>
        <List dense={true}>
          <ListItem>
            <ListItemText
              primary={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar>
                    <TravelExploreIcon color='success' />
                  </Avatar>
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    {t("information.list1Title")}
                  </span>
                </div>
              }
              secondary={
                <Typography
                  component={"div"}
                  className={"css-ty9jyy-MuiTypography-root"}>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>{t("information.list1Sub1")}</li>
                    <li>{t("information.list1Sub2")}</li>
                    <li>{t("information.list1Sub3")}</li>
                  </ul>
                </Typography>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar>
                    <ForestIcon color='success' />
                  </Avatar>
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    {t("information.list2Title")}
                  </span>
                </div>
              }
              secondary={
                <Typography
                  component={"div"}
                  className={"css-ty9jyy-MuiTypography-root"}>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>{t("information.list2Sub1")}</li>
                    <li>{t("information.list2Sub2")}</li>
                    <li>{t("information.list2Sub3")}</li>
                  </ul>
                </Typography>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar>
                    <EmojiNatureIcon color='success' />
                  </Avatar>
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    {t("information.list3Title")}
                  </span>
                </div>
              }
              secondary={
                <Typography
                  component={"div"}
                  className={"css-ty9jyy-MuiTypography-root"}>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>{t("information.list3Sub1")}</li>
                    <li>{t("information.list3Sub2")}</li>
                    <li>{t("information.list3Sub3")}</li>
                    <li>{t("information.list3Sub4")}</li>
                  </ul>
                </Typography>
              }
            />
          </ListItem>
        </List>
        <p>{t("information.text3")}</p>
      </Grid>
    </Grid>
  );
};
