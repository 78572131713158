// Language loading
import enFlag from "../images/flags/en.png";
import esFlag from "../images/flags/es.png";
import caFlag from "../images/flags/ca.png";


export const supportedLanguages = [
  { code: "ca", name: "Catala", flag: caFlag, route: "/home" },
  { code: "es", name: "Español", flag: esFlag, route: "/home" },
  { code: "en-gb", name: "English", flag: enFlag, route: "/home" },
  //{ code: "fr", name: "Français", flag: frFlag, route: "/simpleHome" },
  //{ code: "de", name: "Deutsche", flag: deFlag, route: "/simpleHome" },
  // Add more supported languages as needed
];


// Park prices
export const prices = {
  year: 2023,
  booking: 40,
  adult: 11,
  kids: 7.5,
  toddler: 0,
  retired: 8.5,
  teacher: 9.35,
  disabledA: 8.25,
  disabledK: 5.62,
  donkey: 1,
  golf: 2,
  tour: 0.5,
};

// Calendar Colors
export const colors = [
  "white",
  "#6ba37e",
  "#ffe438",
  "#ee3434",
  "#2DA8B8",
  "#CC66CC",
];
  
// List of seasons with start and end dates
export const seasons = [
  {
    start: "2023-03-17",
    end: "2023-05-31",
    season: "low",
  },
  {
    start: "2023-05-31",
    end: "2023-06-24",
    season: "highstart",
  },
  {
    start: "2023-06-24",
    end: "2023-09-04",
    season: "high",
  },
  {
    start: "2023-09-04",
    end: "2023-11-27",
    season: "low",
  },
  {
    start: "2023-11-27",
    end: "2023-12-27",
    season: "lowend",
  },
];
  
// List of special days that we are closed/open. Format yyyy-mm-dd
export const special = [
  { date: "2023-04-03", start: "10:30", end: "17:00" },
  { date: "2023-04-04", start: "10:30", end: "17:00" },
  { date: "2023-04-05", start: "10:30", end: "17:00" },
  { date: "2023-04-06", start: "10:30", end: "17:00" },
  { date: "2023-04-07", start: "10:30", end: "17:00" },
  { date: "2023-04-10", start: "10:30", end: "17:00" },
  { date: "2023-04-15", start: false },
  { date: "2023-05-01", start: "10:30", end: "17:00" },
  { date: "2023-06-05", start: "10:30", end: "18:00" },
  { date: "2023-06-06", start: "10:30", end: "18:00" },
  { date: "2023-07-07", start: false },
  { date: "2023-07-08", start: false },
  { date: "2023-07-09", start: false },
  { date: "2023-08-14", start: "10:30", end: "18:00" },
  { date: "2023-09-09", start: false },
  { date: "2023-09-10", start: false },
  { date: "2023-09-25", start: "10:30", end: "17:00" },
  { date: "2023-09-26", start: "10:30", end: "17:00" },
  { date: "2023-10-12", start: "10:30", end: "17:00" },
  { date: "2023-10-13", start: "10:30", end: "17:00" },
  { date: "2023-11-01", start: "10:30", end: "17:00" },
  { date: "2023-12-06", start: "11:00", end: "16:00" },
  { date: "2023-12-07", start: "11:00", end: "16:00" },
  { date: "2023-12-08", start: "11:00", end: "16:00" },
  { date: "2023-12-25", start: "11:00", end: "16:00" },
  { date: "2023-12-26", start: "11:00", end: "16:00" },
];

//  List of time tables
export const times: {
  [key: string]: {
    [key: string]:
    | boolean
    | { start: string; end: string; tourAM: string; tourPM?: string }
    | string;
  };
  } = {
    
    low: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Holiday: { start: "10:30", end: "17:00", tourAM: "11:00" },
    },
    lowend: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "11:00", end: "16:00", tourAM: "11:30" },
      Sun: { start: "11:00", end: "16:00", tourAM: "11:30" },
      Holiday: { start: "11:00", end: "16:00", tourAM: "11:30" },
    },
    highstart: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Holiday: { start: "10:30", end: "17:00", tourAM: "11:00" },
    },
    high: {
      Mon: false, //closed,
      Tue: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Wed: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Thu: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Fri: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Holiday: { start: "10:30", end: "18:00", tourAM: "11:00" },
    },
};