import { Grid, Typography, Button, IconButton } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//icons
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

export const Carousel = () => {
  const { t, i18n } = useTranslation();

  const AdditionalBox = () => {
    return (
      <Grid className='boxCaroselBottom'>
        <Grid className='carouselBorder' padding={"10px"}>
          <Grid className='centerRow'>
            <Grid item width={"33%"}>
              <Link
                reloadDocument //makes anchor work
                to={`/${i18n.language}/planYourVisit#calendar`}
                aria-label={t("carousel.openTime").toString()}>
                <IconButton aria-label={t("carousel.openTime").toString()}>
                  <CalendarMonthOutlinedIcon />
                </IconButton>
                <Typography>{t("carousel.openTime")}</Typography>
              </Link>
            </Grid>
            <Grid item width={"33%"}>
              <Link
                reloadDocument
                to={`/${i18n.language}/planYourVisit#prices`}
                aria-label={t("carousel.prices").toString()}>
                <IconButton aria-label={t("carousel.prices").toString()}>
                  <EuroOutlinedIcon />
                </IconButton>
                <Typography>{t("carousel.prices")}</Typography>
              </Link>
            </Grid>
            <Grid item width={"33%"}>
              <Link
                reloadDocument
                to={`/${i18n.language}/planYourVisit#map`}
                aria-label={t("carousel.getHere").toString()}>
                <IconButton aria-label={t("carousel.getHere").toString()}>
                  <LocationOnOutlinedIcon />
                </IconButton>
                <Typography>{t("carousel.getHere")}</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderTextAndButton = (
    title: string,
    titleSub: string,
    buttonLabel: string,
    link: string
  ) => {
    return (
      <Grid className='boxCaroselTop'>
        <Grid className='carouselBorderTop' padding={"10px"}>
          <Grid container className='center' direction={"column"}>
            <Typography variant='h5'>{title}</Typography>
            <Typography>{titleSub}</Typography>
            {buttonLabel && (
              <Link to={`/${i18n.language}${link}`}>
                <Button
                  variant='contained'
                  color='primary'
                  aria-label={buttonLabel}>
                  {buttonLabel}
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid marginBottom={-1}>
      <Swiper
        rewind={true}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        style={{ height: "100%" }}
        autoplay={{
          delay: 7000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay, Navigation]}>
        {/* New Website */}
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800
                  ? require("../../images/gould-sm.jpg")
                  : require("../../images/gould.jpg")
              }
              alt='gould'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.newWeb"),
              t("carousel.newWebSub"),
              t(""),
              ""
            )}
            <AdditionalBox />
          </Grid>
        </SwiperSlide>

        {/* Gift Card */}
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800
                  ? require("../../images/Gift_Cards-sm.jpg")
                  : require("../../images/Gift_Cards.jpg")
              }
              alt='giftCards'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.giftcard"),
              t("carousel.giftcardSub"),
              t("carousel.giftcardButton"),
              "/giftCard"
            )}
            <AdditionalBox />
          </Grid>
        </SwiperSlide>

        {/* Year Pass */}
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800
                  ? require("../../images/pase_anual-sm.jpg")
                  : require("../../images/pase_anual.jpg")
              }
              alt='pase_anual'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.yearpass"),
              t("carousel.yearpassSub"),
              t(""),
              ""
            )}
            <AdditionalBox />
          </Grid>
        </SwiperSlide>

        {/* Shop */}
        <SwiperSlide>
          <Grid>
            <img
              src={
                window.innerWidth < 800
                  ? require("../../images/shop-sm.jpg")
                  : require("../../images/shop.jpg")
              }
              alt='shop'
              className='carouselImg'
            />
            {renderTextAndButton(
              t("carousel.shop"),
              t("carousel.shopSub"),
              t(""),
              ""
            )}
            <AdditionalBox />
          </Grid>
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
};
