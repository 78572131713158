import React from "react";
import { Grid } from "@mui/material";
import { NavBarTop } from "../components/Bars/NavBarTop";
import { Carousel } from "../components/Carousel/Carousel";
import { Information } from "../components/Information";
import Map from "../components/Map";
import { WhatSpot } from "../components/Carousel/WhatSpot";
import { Footer } from "../components/Bars/Footer";

const MainPage = () => {
  return (
    <Grid>
      <Grid container>
        <NavBarTop />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Carousel />
        </Grid>
        <Grid item md={12} lg={6} className='boxGreen'>
          <Information />
        </Grid>
        <Grid item md={12} lg={6} className='boxGreen'>
          <Map />
        </Grid>
        <Grid item xs={12} className='boxBlue'>
          <WhatSpot />
        </Grid>
        <Grid container>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainPage;
