import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Button,
  List,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  Fab,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TranslateIcon from "@mui/icons-material/Translate";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Import the language
import { supportedLanguages } from "../../data/data";

const drawerWidth = 240;

export const NavBarTop = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [languageMenuAnchor, setLanguageMenuAnchor] =
    React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const currentPath = location.pathname;

  const navItems = [
    { label: "menu.home", link: `/${i18n.language}/home` },
    { label: "menu.planYourVisit", link: `/${i18n.language}/planYourVisit` },
    { label: "menu.party", link: `/${i18n.language}/party` },
    { label: "menu.giftCard", link: `/${i18n.language}/giftCard` },
    { label: "menu.contact", link: `/${i18n.language}/contact` },
    { label: "menu.faq", link: `/${i18n.language}/faq` },
    { label: "menu.school", link: `/${i18n.language}/school` },
  ];

  const shouldShowHomeItem = !currentPath.includes("/simpleHome");

  // Check if a language is stored in local storage and set it as the initial language
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      document.documentElement.lang = storedLanguage; // Update lang attribute on HTML tag
    }
  }, [i18n]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLanguageMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setLanguageMenuAnchor(event.currentTarget);
  };

  const handleLanguageMenuClose = (event: React.MouseEvent<Document>) => {
    event.stopPropagation();
    setLanguageMenuAnchor(null);
  };

  const handleLanguageChange = (
    lang: string,
    route: string,
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang); // Store the selected language in local storage
    event.stopPropagation();
    setLanguageMenuAnchor(null);
    document.documentElement.lang = lang; // Update lang attribute on HTML tag
    navigate(`/${lang}${route}`);
  };

  const languageCode = i18n.language;
  const selectedLanguage = supportedLanguages.find(
    (lang) => lang.code === languageCode
  );
  const route = selectedLanguage ? selectedLanguage.route : "/home";
  const logo = (
    <>
      <Link to={`/${languageCode}${route}`}>
        <IconButton aria-label='Logo'>
          <Avatar
            alt='Logo'
            src={require("../../images/logo.png")}
            sizes='large'
            sx={{
              display: { width: 75, height: 75, xs: "block", md: "block" },

              ml: -3,
            }}
          />
        </IconButton>
      </Link>
      <Link
        to={`/${languageCode}${route}`}
        style={{ textDecoration: "none", color: "inherit" }}>
        <Typography
          variant='h4'
          color={"#ffea00"}
          sx={{
            paddingTop: 1.5,
          }}>
          El Jardi Dels Ocells
        </Typography>
        {t("parkName") !== "El Jardí Dels Ocells" && (
          <Typography
            variant='h6'
            sx={{
              paddingBottom: 1.5,
            }}>
            ({t("parkName")})
          </Typography>
        )}
      </Link>
    </>
  );

  const languageButton = (
    <>
      <Button
        size='small'
        variant='contained'
        color='success'
        aria-haspopup='true'
        aria-label={t("menu.language").toString()}
        onClick={handleLanguageMenuOpen}>
        <TranslateIcon />
        {t("menu.language")}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id='language-menu'
        anchorEl={languageMenuAnchor}
        open={Boolean(languageMenuAnchor)}
        onClose={handleLanguageMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        {supportedLanguages.map((lang) => (
          <MenuItem
            key={lang.code}
            onClick={(event) =>
              handleLanguageChange(lang.code, lang.route, event)
            }>
            <ListItemIcon>
              <img
                src={lang.flag}
                alt={lang.name}
                style={{ height: "1.5em", borderRadius: "50%" }}
              />
            </ListItemIcon>
            {lang.name}
          </MenuItem>
        ))}
        <MenuItem key='crowdin'>
          <ListItemIcon>
            <TranslateIcon />
          </ListItemIcon>
          <Link
            to='https://crowdin.com/project/el-jardi-dels-ocells-simp'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: "none", color: "inherit" }}>
            Help us add more
          </Link>
        </MenuItem>
      </Menu>
    </>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {shouldShowHomeItem ? (
        <List>
          {navItems.map((item) => (
            <Link to={item.link} key={item.label}>
              <ListItem key={item.label} disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText primary={t(item.label)} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      ) : null}
      {languageButton}
    </Box>
  );

  const floatingButton = (
    <Fab
      onClick={handleDrawerToggle}
      aria-label='Floating Button'
      sx={{
        position: "fixed",
        bottom: "2rem",
        right: "2rem",
        display: { xs: "block", md: "none" },
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      }}>
      <HomeIcon />
    </Fab>
  );

  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "block", md: "none" } }}>
            <MenuIcon />
          </IconButton>
          {logo}
          <Box
            justifyContent={"flex-end"}
            sx={{
              display: { xs: "none", md: "block" },
              ml: "auto", // Align items to the right
            }}>
            {shouldShowHomeItem ? (
              <List
                sx={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                {navItems.map((item) => (
                  <ListItem key={item.label} sx={{ flexBasis: "10%" }}>
                    <Link to={item.link}>
                      <Button
                        size='medium'
                        variant='contained'
                        color='secondary'
                        sx={{ whiteSpace: "nowrap" }}>
                        {t(item.label)}
                      </Button>
                    </Link>
                  </ListItem>
                ))}
              </List>
            ) : null}
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
            }}>
            {languageButton}
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex" }}>{floatingButton}</Box>
      <Box component='nav'>
        {/* marginTop={`${theme.mixins.toolbar.minHeight}px`} */}
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
