import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "../components/Bars/Footer";
import { NavBarTop } from "../components/Bars/NavBarTop";
import { FaqBuild } from "../components/FaqBuild";

export const FAQ = () => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid container>
        <NavBarTop />
      </Grid>
      <Grid container>
        <Grid item xs={12} marginBottom={"-5px"}>
          <Grid className='pageImg'>
            <div style={{ position: "relative" }}>
              <img
                src={require("../images/headers/owlfinch.jpg")}
                alt='map'
                className='pageImg'
              />
              <div className='pageTitle'>
                <Typography variant='h4' className='boxBorder' padding={1}>
                  {t("menu.faqDef")}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item>
          <FaqBuild />
        </Grid>
      </Grid>
      <Grid container>
        <Footer />
      </Grid>
    </Grid>
  );
};
