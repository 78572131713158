import { Grid, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const StyledIframe = styled("iframe")({
  width: "100%",
  border: "none",
  height: "2300px",
  "@media (min-width:900px)": {
    width: "80%",
    height: "2100px",
  },
});

const LoadingContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "2300px", // Adjust the height as needed
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const LoadingOverlay = styled(CircularProgress)({
  position: "absolute",
  top: "50vh",
});

export const GiftCardFrame = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{t("gift.info1")}</Typography>
        <Typography>{t("gift.info2")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <LoadingContainer>
          {isLoading && <LoadingOverlay />} {/* Show loading icon at the top */}
          <StyledIframe
            src='https://squareup.com/gift/ML381BARER02M/order'
            title='Square GiftCard'
            onLoad={handleIframeLoad}
          />
        </LoadingContainer>
      </Grid>
    </Grid>
  );
};
