import React, { useState, useContext } from "react";
import {
  Card,
  Grid,
  CardHeader,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  Button,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Calendar from "react-calendar";
import "../Calendar.css";

import { seasons, special, times, colors } from "../data/data";

import "dayjs/locale/es";
import "dayjs/locale/en-gb";
import "dayjs/locale/ca";

export const TimeTable = ({
  tour,
  calendar,
}: {
  tour: boolean;
  calendar: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const LanguageContext = React.createContext("");

  const currentYear = new Date().getFullYear(); // Get the current year
  const currentMonth = new Date().getMonth(); // Get the current month
  const remainingMonths = 12 - currentMonth; // Calculate the remaining months

  const className = "boxYellow";
  const className2 = "boxGreen";

  const getFormattedDate = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${day}/${month}`;
  };

  const currentDate = new Date();
  const currentSeason = seasons.find(
    (s) => currentDate >= new Date(s.start) && currentDate <= new Date(s.end)
  );

  const seasonText =
    currentSeason &&
    (currentSeason.season === "high" || currentSeason.season === "highstart"
      ? t("time.highSeason")
      : currentSeason.season === "low" || currentSeason.season === "lowend"
      ? t("time.lowSeason")
      : "");

  const generateRowsTours = () => {
    if (currentSeason) {
      const seasonTourTimes = times[currentSeason.season];
      const rows: {
        days: string[];
        tourTimeAM: string;
        tourTimePM: string;
      }[] = Object.entries(seasonTourTimes).reduce(
        (
          acc: {
            days: string[];
            tourTimeAM: string;
            tourTimePM: string;
          }[],
          [dayOfWeek, tourTimes]
        ) => {
          const prevRow = acc[acc.length - 1];

          const formattedDayOfWeek = t(
            `time.weekday.${dayOfWeek.toLowerCase()}`
          );
          let tourTimeAM: string;
          let tourTimePM: string;

          if (typeof tourTimes === "object" && tourTimes.tourAM) {
            tourTimeAM = tourTimes.tourAM + "AM";
          } else if (prevRow && prevRow.tourTimeAM === t("time.closed")) {
            tourTimeAM = t("time.closed");
          } else {
            tourTimeAM = "";
          }

          if (typeof tourTimes === "object" && tourTimes.tourPM) {
            tourTimePM = tourTimes.tourPM + "PM";
          } else if (prevRow && prevRow.tourTimePM === t("time.closed")) {
            tourTimePM = t("time.closed");
          } else {
            tourTimePM = "";
          }

          if (
            prevRow &&
            prevRow.tourTimeAM === tourTimeAM &&
            prevRow.tourTimePM === tourTimePM
          ) {
            if (prevRow.days.length === 1) {
              prevRow.days.push(formattedDayOfWeek);
            } else {
              prevRow.days[1] = formattedDayOfWeek;
            }
          } else {
            acc.push({
              days: [formattedDayOfWeek],
              tourTimeAM,
              tourTimePM,
            });
          }

          return acc;
        },
        []
      );

      const tableRows = rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.days.join("-")}</TableCell>
          <TableCell>{row.tourTimeAM || t("time.closed")}</TableCell>
          <TableCell>{row.tourTimePM || ""}</TableCell>
        </TableRow>
      ));

      return tableRows;
    }

    return t("time.unavailable");
  };

  const generateRows = (numDays: number, selectedDate: Date | null = null) => {
    const rows = [];
    const currentDate = selectedDate ? new Date(selectedDate) : new Date();
    for (let i = 0; i < numDays; i++) {
      const nextDate = new Date(
        currentDate.getTime() + 3 * 60 * 60 * 1000 + i * 24 * 60 * 60 * 1000
      );
      const formattedDate = getFormattedDate(nextDate);
      const dayOfWeek = nextDate.toLocaleDateString("en-US", {
        weekday: "short",
      });

      const season = seasons.find(
        (s) => nextDate >= new Date(s.start) && nextDate < new Date(s.end)
      );
      const timetable = season ? times[season.season][dayOfWeek] : null;

      // Check if the current day is a special day
      const specialDay = special.find(
        (s) => s.date === nextDate.toISOString().slice(0, 10)
      );

      let cellContent = t("time.closed");
      if (specialDay) {
        if (specialDay.start === false) {
          cellContent = t("time.closed");
        } else {
          cellContent = `${specialDay.start} - ${specialDay.end}`;
        }
      } else if (timetable) {
        if (typeof timetable === "object") {
          cellContent = `${timetable.start} - ${timetable.end}`;
        }
      }

      const dayLabel =
        i === 0 && !selectedDate
          ? t("time.today")
          : t(`time.weekday.${dayOfWeek.toLowerCase()}`);

      rows.push(
        <TableRow key={i}>
          <TableCell>
            {dayLabel} ({formattedDate})
          </TableCell>
          <TableCell>{cellContent}</TableCell>
        </TableRow>
      );
    }

    return rows;
  };

  // Custom function to render content for each day
  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    const day = date.getDay();
    //Get current day
    const currentDate = new Date(
      date
        .toLocaleDateString(lang, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("/")
    );
    // Check if the current day is a special day
    // Subtract one day from currentDate
    const previousDay = new Date(currentDate);
    previousDay.setDate(previousDay.getDate() + 1);
    const specialDay = special.find(
      (s) => s.date === previousDay.toISOString().slice(0, 10)
    );
    // Get season of current day
    let currentSeason = seasons.find(
      (s) => currentDate >= new Date(s.start) && currentDate < new Date(s.end)
    );

    // Add your conditions to determine the color of specific days
    const fontSize = "11px";
    if (specialDay) {
      if (specialDay.start === false || specialDay.start === "false") {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else {
        if (currentSeason && currentSeason.season === "low") {
          return (
            <div style={{ background: colors[1], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        } else if (currentSeason && currentSeason.season === "highstart") {
          return (
            <div style={{ background: colors[2], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        } else if (currentSeason && currentSeason.season === "high") {
          return (
            <div style={{ background: colors[2], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        } else if (currentSeason && currentSeason.season === "lowend") {
          return (
            <div style={{ background: colors[5], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        }
      }
    } else if (currentSeason && currentSeason.season === "low") {
      if (day >= 1 && day <= 5) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day === 0 || day === 6) {
        return (
          <div style={{ background: colors[1], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    } else if (currentSeason && currentSeason.season === "highstart") {
      if (day >= 1 && day <= 5) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day === 0) {
        return (
          <div style={{ background: colors[3], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      } else if (day === 6) {
        return (
          <div style={{ background: colors[4], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    } else if (currentSeason && currentSeason.season === "high") {
      if (day === 1) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day >= 2 && day <= 4) {
        return (
          <div style={{ background: colors[2], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      } else if (day === 0 || day === 5) {
        return (
          <div style={{ background: colors[3], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      } else if (day === 6) {
        return (
          <div style={{ background: colors[4], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    } else if (currentSeason && currentSeason.season === "lowend") {
      if (day >= 1 && day <= 5) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day === 0 || day === 6) {
        return (
          <div style={{ background: colors[5], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    }
    return (
      <div style={{ background: colors[0], fontSize: fontSize }}>
        {t("time.closed")}
      </div>
    );
  };

  const TableComponent = () => {
    const numDaysDefault = 8;
    const [numDays, setNumDays] = useState(numDaysDefault);
    const [originalNumDays] = useState(numDaysDefault);

    const [showRevertButton, setShowRevertButton] = useState(false);

    const handleAddDays = () => {
      if (numDays < 20) {
        setNumDays(numDays + 3);
        setShowRevertButton(true);

        // Scroll to the bottom of the element after a small delay
        setTimeout(() => {
          const element = document.getElementById("buttonStack");
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 100);
      }
    };

    const handleRevertDays = () => {
      setNumDays(originalNumDays);
      setShowRevertButton(false);
    };

    const [showCalendar, setShowCalendar] = useState(false);
    const [showWeek, setShowWeek] = useState(false);
    const [showMoreDaysButton, setShowMoreDaysButton] = useState(true);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

    const handleToggleCalendar = () => {
      setShowCalendar(!showCalendar);
      setShowWeek(!showWeek);
      setShowMoreDaysButton(!showMoreDaysButton);
      setSelectedDate(new Date());
    };

    const handleDateChange = (value: Date | null) => {
      setSelectedDate(value);
    };

    const LocalizedCalendar = () => {
      const languageCode = useContext(LanguageContext);

      return (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={languageCode}>
          <DateCalendar
            views={["day"]}
            disablePast
            onChange={handleDateChange}
            dayOfWeekFormatter={(day) => `${day}`}
          />
        </LocalizationProvider>
      );
    };

    if (tour)
      return (
        <Grid padding={1}>
          <Grid className={className2}>
            <Card className='boxBorder'>
              <CardHeader
                title={
                  <Typography variant='h5'>
                    {t("time.titleTour")} {"/ "}
                    {seasonText}
                  </Typography>
                }
                className={className2}
              />
              <Stack id='tourTime'>
                <TableContainer className={className2}>
                  <Table size='small'>
                    <tbody>{generateRowsTours()}</tbody>
                  </Table>
                </TableContainer>
              </Stack>
              <Typography bgcolor={"lightgoldenrodyellow"}>
                {t("time.tourLang")}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      );
    if (calendar)
      return (
        <Grid container>
          <Grid container spacing={2} padding={2}>
            <Grid item lg={12}>
              <Typography variant='h5' textAlign={"center"}>
                {currentYear} {t("calendar.calendar")}
              </Typography>
              <Grid
                container
                direction='row'
                gap={1}
                justifyContent={"space-around"}>
                {/* CLOSED days */}
                <Typography padding={1} bgcolor={colors[0]}>
                  {t("calendar.closed")}
                </Typography>
                {/* Low Season days */}
                <Typography padding={1} bgcolor={colors[1]}>
                  {t("calendar.low")} 10:30 - 17:00
                </Typography>
                {/* High Season days */}
                <Typography padding={1} bgcolor={colors[2]}>
                  {t("calendar.high")} 10:30 - 18:00
                </Typography>
                {/* High Season days */}
                <Typography padding={1} bgcolor={colors[4]}>
                  {t("calendar.high")} 10:30 - 17:00
                </Typography>
                {/* High Season days */}
                <Typography padding={1} bgcolor={colors[3]}>
                  {t("calendar.high")} 10:30 - 20:00
                </Typography>
                {/* December days */}
                <Typography padding={1} bgcolor={colors[5]}>
                  {t("calendar.Dec")} 11:00 - 16:00
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {[...Array(remainingMonths)].map((_, index) => {
              const month = currentMonth + index;
              const calendarDate = new Date(currentYear, month, 1);
              const monthName = calendarDate.toLocaleString(lang, {
                month: "long",
              });
              return (
                <Grid item padding={2} xs={12} sm={6} md={3} key={index}>
                  <Typography variant='h5' gutterBottom>
                    {monthName} {currentYear}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pointerEvents: "none",
                    }}>
                    <Calendar
                      defaultActiveStartDate={calendarDate}
                      view='month'
                      locale={lang}
                      showNeighboringMonth={false}
                      tileContent={tileContent}
                      showNavigation={false}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      );
    else
      return (
        <Grid padding={1} id='calendar'>
          <Grid className={className}>
            <Card className='boxBorder'>
              <CardHeader
                title={<Typography variant='h5'>{t("time.title")}</Typography>}
                className={className}
              />
              {!showCalendar ? (
                <Stack id='timeTable'>
                  <TableContainer className={className}>
                    <Table>
                      <tbody>{generateRows(numDays)}</tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              ) : (
                <Stack id='calendar' className={className}>
                  <LanguageContext.Provider
                    value={localStorage.getItem("language") || "es"}>
                    <LocalizedCalendar />
                  </LanguageContext.Provider>
                  {selectedDate &&
                  selectedDate <= new Date(seasons[seasons.length - 1].end) ? (
                    <TableContainer className={className}>
                      <Table>
                        <tbody>{generateRows(1, selectedDate)}</tbody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant='subtitle1'>
                      {t("time.unavailable")}
                    </Typography>
                  )}
                </Stack>
              )}
              <Stack
                id='buttonStack'
                spacing={2}
                direction='row'
                className={className}>
                {showMoreDaysButton && (
                  <Button
                    id='moreDaysButton'
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={handleAddDays}>
                    {t("time.moreDays")}
                  </Button>
                )}
                {showRevertButton && (
                  <Button
                    id='revertDaysButton'
                    size='small'
                    variant='contained'
                    color='secondary'
                    onClick={handleRevertDays}>
                    {t("time.revert")}
                  </Button>
                )}
                <Button
                  id='pickDateButton'
                  size='small'
                  variant='contained'
                  color='warning'
                  onClick={handleToggleCalendar}>
                  {showWeek ? t("time.showWeek") : t("time.pickDate")}
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      );
  };
  return <TableComponent />;
};

export default TimeTable;
