import "./App.css";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";

import FirstLoad from "./pages/FirstLoad";
import MainPage from "./pages/MainPage";
import { PlanYourVisit } from "./pages/PlanYourVisit";
import { Contact } from "./pages/Contact";
import { GiftCard } from "./pages/GitftCard";
import { MainSimplePage } from "./pages/MainSimplePage";
import { Party } from "./pages/Party";
import { useTranslation } from "react-i18next";
import { TCPage } from "./pages/TCPage";
import { FAQ } from "./pages/FAQ";
import { School } from "./pages/School";
//import { Adopt } from "./pages/Adopt";

ReactGA.initialize("G-RNKZ267Z00");

function App() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieConsent
        location='bottom'
        expires={7}
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        buttonText={t("cookiesOkay")}
        cookieName='myCookieConsent'
        style={{ background: "#4caf50", color: "white" }}
        buttonStyle={{
          background: "white",
          color: "#4caf50",
          fontSize: "20px",
        }}>
        {t("cookies")}
      </CookieConsent>
      <Router>
        <div className='App'>
          <Routes>
            <Route path='/' element={<FirstLoad />} />
            <Route path='/:lang/home' element={<MainPage />} />
            <Route path='/:lang/simpleHome' element={<MainSimplePage />} />
            <Route path='/:lang/planYourVisit' element={<PlanYourVisit />} />
            <Route path='/:lang/giftCard' element={<GiftCard />} />
            <Route path='/:lang/contact' element={<Contact />} />
            <Route path='/:lang/FAQ' element={<FAQ />} />
            {/* <Route path='/:lang/Adopt' element={<Adopt />} /> */}
            <Route path='/:lang/party' element={<Party />} />
            <Route path='/:lang/school' element={<School />} />
            <Route path='/:lang/tc' element={<TCPage />} />
            {/* handles non existant pages aka. 404 */}
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
